import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We recently hosted a meetup in NYC to discuss brand strategies in conversational interfaces. As the space continues to mature, more brands are getting involved and moving from experimental to production deployments. We assembled a great group of industry experts to share their thoughts and tips for brands looking to build a chatbot or voice skill.`}</p>
    <p>{`Our panelists included:`}</p>
    <ul>
      <li parentName="ul">{`Alec Truitt, Global Product Partnerships, `}<a parentName="li" {...{
          "href": "https://assistant.google.com/"
        }}>{`Google Assistant`}</a></li>
      <li parentName="ul">{`Anamita Guha, Global Lead of Product Management, `}<a parentName="li" {...{
          "href": "https://www.research.ibm.com/ibm-q/"
        }}>{`IBM Q`}</a></li>
      <li parentName="ul">{`Antonio Cucciniello, Senior Software Developer, `}<a parentName="li" {...{
          "href": "https://www.reprisedigital.com/"
        }}>{`Reprise Digital`}</a></li>
      <li parentName="ul">{`Brian Plaskon, Senior Product Manager, `}<a parentName="li" {...{
          "href": "https://www.realogy.com/"
        }}>{`Realogy Holdings`}</a></li>
    </ul>
    <h2>{`What use cases are brands developing for?`}</h2>
    <p>{`Our panelists see a wide variety of use cases from customer service, productivity tools, and information sharing to entertainment and marketing initiatives.`}</p>
    <p>{`At IBM, they work with everyone from individual developers to Fortune 20 enterprises. The most common use case, though, is customer care. Large enterprises go to IBM given their ability to handle sensitive data across industry and geographic regulations.`}</p>
    <p>{`Google works with a similar range of users and enterprises. The use cases for Google Assistant Actions depend on the context – in the home (recipes, home automation), mobile (games, local search), or in the car on the go (communication, messaging).`}</p>
    <p>{`Reprise Digital, a global marketing agency, tends to work with brands looking to experiment on a new platform, market their products, or provide information and FAQs. Given voice is a new platform, there are some set of brands that want to be first on it. We also see this frequently with innovation teams experimenting in voice. On the text chatbot side, Reprise generally sees brands looking to promote their products or answer FAQs.`}</p>
    <p>{`Realogy, the real estate holding company for Century21, Coldwell Banker, Sotheby’s, and more, developed its own voice skill, AgentX. Agent X is a productivity skill that enables real estate agents to quickly get information, including their appointments, market research, and listing information – all without the need to open their laptop.`}</p>
    <h2>{`Tips from the experts – what works well for conversational interfaces?`}</h2>
    <h3>{`Context is key`}</h3>
    <p>{`A common theme throughout the evening was the importance of context. Where is the user? What are they currently doing? What are they looking for or to do? What are the capabilities of the device?`}</p>
    <p>{`In addition to the three contexts Alec pointed out earlier (in the home, mobile, and in the car), he recommended also considering when will the user interacts with the chatbot and how the user expects to interact with it. If the user is in the car, they cannot use their hands. If they are at home getting a recipe, having something visual can help. If the user wants to play a game, but is on a device without a screen, perhaps a quiz is better.`}</p>
    <p>{`Whether the interface is voice enabled or text only is important. As Anamita points out, users talk differently than they type.`}</p>
    <p>{`Trust can also be an important factor. As IBM works with many global enterprises handling sensitive data, one of the areas Anamita sees is that a user may be more likely to trust interacting on a computer, where identity can be more easily verified, rather than via voice.`}</p>
    <h3>{`Leverage the conversational nature of interfaces`}</h3>
    <p>{`Conversational interfaces are quite a bit different from websites and mobile apps – what works well in those may not work well in a chatbot or voice skill.`}</p>
    <blockquote>
      <p parentName="blockquote">{`It is important not to replicate what you already have on your website or app, but to consider what will add value to the user and what is faster to use`}</p>
    </blockquote>
    <p>{`At Reprise, Antonio sometimes sees brands who want to port what they currently have on their website or mobile app to a chatbot or voice interface. Whereas the website is all about navigating from one link to the other to vertically drill into what the user is looking for, with conversation, a user should be able to say, or write, what they want and directly get to the information.`}</p>
    <p>{`Similarly with Realogy, agents are looking for information that is stored in a variety of locations and want to be able to retrieve it quickly and easily. A powerful example of Agent X is when a listing agent is in a presentation with a seller and wants to know the average time on the market or average listing price for a property, they can quickly get the info, without the need to open a laptop and start searching.`}</p>
    <p>{`As Alec adds, there is a low tolerance amongst users of voice skills. If the skill is not useful, or they do not see the value right away, they will jump to something else. If they see a great use case, they will invest the extra time. It is important not to replicate what you already have on your website or app, but to consider what will add value to the user and what is faster to use.`}</p>
    <h3>{`Keep it simple, provide guidance`}</h3>
    <p>{`Another common theme amongst our panels was to start simple.`}</p>
    <p>{`As Alec pointed out, with voice, it is especially important to keep interactions simple rather than complex back and forths. For example, placing an order for food delivery from scratch can be rather complex. However, re-ordering a previous delivery is much easier, and better suited to voice. We see the same thing with the food delivery clients we work with. We also learned from our `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2018/12/19/examining-consumer-purchasing-behavior-through-alexa-and-google-home/"
      }}>{`voice survey`}</a>{`, that approximately 53% of purchases through voice interfaces is for food delivery.`}</p>
    <p>{`In general, the process Alec sees developers follow with Google Actions is to first build the foundation and make sure the Action works, next layer in visuals and make it more interactive, and finally delight the user so they come back and re-engage.`}</p>
    <p>{`Simplicity was also a key goal for Realogy. One of the most popular use cases is just looking up real estate listings. They plan to build new capabilities where it makes sense. They are not just doing voice for voice sake.`}</p>
    <p>{`To get started, Brian recommends going through the design exercise at `}<a parentName="p" {...{
        "href": "https://alexa.design/cdw"
      }}>{`https://alexa.design/cdw`}</a>{` and to read “`}<a parentName="p" {...{
        "href": "https://developer.amazon.com/docs/custom-skills/understanding-how-users-invoke-custom-skills.html"
      }}>{`Understand how users invoke custom skills`}</a>{`” to add variety into the interaction model.`}</p>
    <p>{`In addition to starting simple, it is important to let the user know what the chatbot or voice skill can do. While Alec suggested the initial interaction can intro what the chatbot can do, it should not do that every time. If the user comes back, allow the user to take the next steps. Anamita recommends including a “fallback” Intent to catch cases the chatbot does not handle. If the fallback is triggered, the chatbot could respond, “sorry I cannot do X, but I can do these six things.”`}</p>
    <h3>{`Personality can be important`}</h3>
    <p>{`Depending on the use case of the chatbot or voice skill, personality can be an important factor.`}</p>
    <p>{`IBM has an “empathy suite” including a tone analyzer and personality insights. When implemented in a customer care chatbot, if a user says they are having a horrible day, the chatbot can understand that and provide a different experience.`}</p>
    <p>{`As users become more comfortable interacting with chatbots and voice, providing a personality can be quite useful. As Anamita explained, initially chatbots were built for efficiency or to automate a task, but now people are creating relationships with them. Children are growing up with bots that have been anthropomorphized, like the `}<a parentName="p" {...{
        "href": "https://www.softbankrobotics.com/emea/en/pepper"
      }}>{`Pepper Robot`}</a>{`.`}</p>
    <p>{`Whether to enable empathy or a personality in a chatbot comes back to context. Anamita sees teaching and therapy use cases as more suited for empathy versus something more transactional. As Brian added, real estate agents range from “Type A” to “Type A,” so they keep personality and cuteness to a minimum. Right now the goal is to be as helpful and productive as possible, but there is room for personality in the future.`}</p>
    <p>{`At Reprise, copywriters try to come up with a voice for the brand – a name and description of the persona and how they would talk. They use this in all the copy as it is important for the voice of the brand to be the same across the entire experience, whether that is on the Internet or voice device.`}</p>
    <p>{`In regards to using voice actors on Alexa or Google Home, the panel in general thought they could be beneficial depending on the use case. For example, Antonio pointed out that if a user were interacting with a Jimmy Fallon skill, they would probably be more engaged if it was his voice rather than the default device’s voice.`}</p>
    <h2>{`User acquisition and discovery are challenges`}</h2>
    <p>{`Our panelists generally agree that user acquisition and discovery are challenges.`}</p>
    <p>{`Education is one of the underlying issues. Some users do not even know third party voice apps exist. As we noted in our earlier `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2018/11/19/how-consumers-really-use-alexa-and-google-home-voice-assistants/"
      }}>{`voice survey`}</a>{`, one of the issues is users tend not to know what voice apps are even called.`}</p>
    <p>{`Similarly, while Antonio finds users tend to not understand how to invoke the voice apps, improving the invocation can lead to increased acquisition. Associating the invocation name with the brand or something popular can help.`}</p>
    <p>{`Making use of the “can fulfill” Intents on Alexa or Google Home can lead to increase acquisition too. If a user asks for something that matches a “can fulfill” Intent, the skill may be presented as a possible option to the user.`}</p>
    <p>{`Discovery can depend on the use case. Anamita finds word-of-mouth tends to work when users are searching for chatbots for need or pleasure. If the use case is more transactional, the enterprise could suggest to the user to try the chatbot instead.`}</p>
    <p>{`Even internal promotion within a company can be a challenge. At Realogy, there are a lot of competing internal marketing initiatives. What worked for Brian was to incentivize folks with device giveaways. He recommends channeling your internal game show host – “there’s a little Steve Harvey in all of us.”`}</p>
    <p>{`Alec’s team at Google is working on solutions to improve user acquisition and discovery. They are not only looking to help with generating initial acquisition, but with retention as well to keep users coming back. One of the challenges is users sometimes do not remember how they found a particular voice app and then how to get back to it.`}</p>
    <h2>{`Analytics are essential`}</h2>
    <p>{`Our panelists all agreed analytics are important. As Alec pointed out, you need great analytics to identify what is driving usage and how to improve it.`}</p>
    <p>{`Realogy uses `}<a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`Dashbot`}</a>{` to gain insights into how agents are interacting with Agent X. They wanted to know if agents would know how to interact with the skill and which features were used more than others. Dashbot helped answer those questions. They also found there is a voracious appetite for new capabilities.`}</p>
    <p>{`Through `}<a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`Dashbot`}</a>{`, Reprise has also been able to improve engagement. They learned for one of their client’s Google Actions, that most of the Intents were not being used. It turned out the issue was with all the overly complicated Intents. Based on the analytics, they launched a new version that was much more simplified.`}</p>
    <p>{`In addition to analytics, Anamita recommends adding a feedback loop directly in the experience. Most of IBM’s internal chatbots include a thumbs up/down prompt asking the user if the chatbot answered the question right or provided the info they needed. At Dashbot, we are able to show these customer satisfaction (CSAT) scores, and the paths leading to them, to help enable improvements in the response effectiveness.`}</p>
    <h2>{`What’s next`}</h2>
    <p>{`We asked our panel if they had any thoughts on the future of the space. There was a great sense of optimism and excitement for the future.`}</p>
    <p>{`Anamita predicts digital humans becoming more of a reality – having interfaces with human facial reactions.`}</p>
    <p>{`Brian is looking forward to a generational leap in the device capabilities – what he likens to going from one game console to the next.`}</p>
    <p>{`Antonio sees users becoming more comfortable with having voice devices in their homes as well as areas for improvement for the space to take off even more. The three main areas are education (knowing how to use the device), discovery (how to find what is needed), and user experience (providing value to users). User experience is the most important to get people to adopt the devices.`}</p>
    <p>{`Alec envisions a blending of voice apps and chatbots to provide the best experience depending on the context, rather than separate experiences. Voice can be the bridge to jump from the starting point to the finish.`}</p>
    <p>{`At Dashbot, we are very excited about the future of conversational interfaces. We look forward to seeing what enterprise brands continue to develop.`}</p>
    <h2>{`Watch the full panel`}</h2>
    <h2><figure className="wp-block-embed-youtube wp-block-embed is-type-video is-provider-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio"><div className="wp-block-embed__wrapper"><iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/5CXMt1X7c5E?feature=oembed" title="Dashbot NYC Meetup Brand Strategies" width="1200"></iframe></div></figure>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}><em parentName="a">{`Dashbot`}</em></a>{` `}<em parentName="p">{`is a conversational analytics platform that enables enterprises and developers to increase user engagement, acquisition, and conversions through actionable insights and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide conversation specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      